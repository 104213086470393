<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="900"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        rounded
        class="py-3 px-4"
        color="primary"
        outlined
        v-bind="attrs"
        small
        v-on="on"
      >
        Ver condições
      </v-btn>
    </template>
    <template #default>
      <v-card class="pa-6 pa-lg-14 py-lg-10">
        <div class="text-right mb-6">
          <CloseBtn @click="dialog = false" />
        </div>
        <div>
          <h3 class="primary--text text-center mb-8">
            Condições do empréstimo
          </h3>
          <div class="contract-box mb-6">
            <small> </small>
          </div>
          <v-checkbox
            v-show="showInsurance"
            v-model="withInsurance"
            :label="insuranceMessage"
            @change="insuranceChanged"
          />
          <v-checkbox :label="iofMessage" input-value="true" value disabled />
          <v-checkbox
            v-show="showTac"
            v-model="withTac"
            :label="tacMessage"
            input-value="true"
            value
            disabled
          />
          <v-checkbox :label="feeMessage" input-value="true" value disabled />
          <v-checkbox :label="cetMessage" input-value="true" value disabled />
          <v-checkbox
            :label="gracePeriodMessage"
            input-value="true"
            value
            disabled
          />
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import CloseBtn from "@/components/CloseBtn.vue";
import formatCurrency from "@/utils/formatCurrency";

export default {
  components: {
    CloseBtn
  },
  props: {
    proposal: {
      required: true,
      type: Object
    },
    showInsurance: {
      default: true,
      type: Boolean
    },
    showTac: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      dialog: false,
      withInsurance: true,
      withTac: true
    };
  },
  computed: {
    insuranceMessage() {
      return this.proposal.insuranceValue
        ? "Seguro Prestamista no valor de " +
            formatCurrency(this.proposal.insuranceValue)
        : "Seguro Prestamista";
    },
    tacMessage() {
      return (
        "Tarifa Gooroo Analisa no valor de " +
        formatCurrency(this.proposal.tacValue)
      );
    },
    iofMessage() {
      return (
        "IOF (Imposto sobre Operações Financeiras) no valor de " +
        formatCurrency(this.proposal.iofValue)
      );
    },
    feeMessage() {
      return `Taxa de juros:
        ${this.proposal.monthlyFeePercentual}% a.m (${this.proposal.annualFeePercentual}% a.a)`;
    },
    cetMessage() {
      return `CET (Custo Efetivo Total):
        ${this.proposal.monthlyCETPercentual}% a.m (${this.proposal.annualCETPercentual}% a.a)`;
    },
    gracePeriodMessage() {
      const gracePeriod = dayjs(this.proposal.installments[0].dueDate).diff(
        dayjs(),
        "days"
      );
      return `Prazo de carência para desconto da 1° parcela: ${gracePeriod} dias`;
    }
  },
  methods: {
    insuranceChanged() {
      this.$emit("insuranceUpdate", this.withInsurance);
    }
  }
};
</script>
<style scoped lang="scss">
.contract-box {
  color: $darkBlue;
  max-height: 500px;
  overflow-y: auto;
}
</style>

