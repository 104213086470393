export default [
  { value: "1", bankNationalCode: "001", text: "001 Banco do Brasil S.A." },
  { value: "2", bankNationalCode: "104", text: "104 Caixa Econômica Federal" },
  { value: "3", bankNationalCode: "341", text: "341 Itaú Unibanco S.A." },
  { value: "4", bankNationalCode: "237", text: "237 Banco Bradesco S.A." },
  { value: "5", bankNationalCode: "422", text: "422 Banco Safra S.A." },
  {
    value: "6",
    bankNationalCode: "033",
    text: "033 Banco Santander (Brasil) S.A."
  },
  {
    value: "7",
    bankNationalCode: "399",
    text: "399 HSBC Bank Brasil S.A. - Banco Múltiplo"
  },
  { value: "9", bankNationalCode: "246", text: "246 Banco ABC Brasil S.A." },
  { value: "10", bankNationalCode: "075", text: "075 Banco ABN AMRO S.A." },
  { value: "11", bankNationalCode: "025", text: "025 Banco Alfa S.A." },
  { value: "12", bankNationalCode: "641", text: "641 Banco Alvorada S.A." },
  {
    value: "13",
    bankNationalCode: "065",
    text: "065 Banco Andbank (Brasil) S.A."
  },
  { value: "14", bankNationalCode: "024", text: "024 Banco BANDEPE S.A." },
  { value: "15", bankNationalCode: "740", text: "740 Banco Barclays S.A." },
  { value: "16", bankNationalCode: "107", text: "107 Banco BBM S.A." },
  { value: "17", bankNationalCode: "096", text: "096 Banco BM&FBOVESPA S.A" },
  { value: "18", bankNationalCode: "318", text: "318 Banco BMG S.A." },
  {
    value: "19",
    bankNationalCode: "752",
    text: "752 Banco BNP Paribas Brasil S.A."
  },
  {
    value: "20",
    bankNationalCode: "248",
    text: "248 Banco Boavista Interatlântico S.A."
  },
  {
    value: "21",
    bankNationalCode: "169",
    text: "169 Banco Bonsucesso Consignado S.A."
  },
  { value: "22", bankNationalCode: "218", text: "218 Banco Bonsucesso S.A." },
  { value: "23", bankNationalCode: "069", text: "069 Banco BPN Brasil S.A." },
  { value: "24", bankNationalCode: "063", text: "063 Banco Bradescard S.A." },
  {
    value: "26",
    bankNationalCode: "204",
    text: "204 Banco Bradesco Cartões S.A."
  },
  {
    value: "27",
    bankNationalCode: "394",
    text: "394 Banco Bradesco Financiamentos S.A."
  },
  { value: "28", bankNationalCode: "208", text: "208 Banco BTG Pactual S.A." },
  { value: "29", bankNationalCode: "263", text: "263 Banco Cacique S.A." },
  {
    value: "30",
    bankNationalCode: "473",
    text: "473 Banco Caixa Geral - Brasil S.A."
  },
  { value: "31", bankNationalCode: "040", text: "040 Banco Cargill S.A." },
  { value: "32", bankNationalCode: "739", text: "739 Banco Cetelem S.A." },
  { value: "33", bankNationalCode: "233", text: "233 Banco Cifra S.A." },
  { value: "34", bankNationalCode: "745", text: "745 Banco Citibank S.A." },
  {
    value: "35",
    bankNationalCode: "000",
    text: "000 Banco CNH Industrial Capital S.A."
  },
  {
    value: "36",
    bankNationalCode: "095",
    text: "095 Banco Confidence de Câmbio S.A."
  },
  {
    value: "37",
    bankNationalCode: "756",
    text: "756 Banco Cooperativo do Brasil S.A. - BANCOOB"
  },
  {
    value: "38",
    bankNationalCode: "748",
    text: "748 Banco Cooperativo Sicredi S.A."
  },
  {
    value: "39",
    bankNationalCode: "222",
    text: "222 Banco Credit Agricole Brasil S.A."
  },
  {
    value: "40",
    bankNationalCode: "505",
    text: "505 Banco Credit Suisse (Brasil) S.A."
  },
  { value: "41", bankNationalCode: "003", text: "003 Banco da Amazônia S.A." },
  {
    value: "42",
    bankNationalCode: "083",
    text: "083 Banco da China Brasil S.A."
  },
  { value: "43", bankNationalCode: "707", text: "707 Banco Daycoval S.A." },
  {
    value: "44",
    bankNationalCode: "000",
    text: "000 Banco de Lage Landen Brasil S.A."
  },
  {
    value: "45",
    bankNationalCode: "456",
    text: "456 Banco de Tokyo-Mitsubishi UFJ Brasil S.A."
  },
  {
    value: "46",
    bankNationalCode: "047",
    text: "047 Banco do Estado de Sergipe S.A."
  },
  {
    value: "47",
    bankNationalCode: "037",
    text: "037 Banco do Estado do Pará S.A."
  },
  {
    value: "48",
    bankNationalCode: "041",
    text: "041 Banco do Estado do Rio Grande do Sul S.A."
  },
  {
    value: "49",
    bankNationalCode: "004",
    text: "004 Banco do Nordeste do Brasil S.A."
  },
  { value: "50", bankNationalCode: "265", text: "265 Banco Fator S.A." },
  { value: "51", bankNationalCode: "224", text: "224 Banco Fibra S.A." },
  { value: "52", bankNationalCode: "626", text: "626 Banco Ficsa S.A." },
  { value: "53", bankNationalCode: "000", text: "000 Banco Ford S.A." },
  { value: "54", bankNationalCode: "000", text: "000 Banco GMAC S.A." },
  { value: "55", bankNationalCode: "612", text: "612 Banco Guanabara S.A." },
  { value: "56", bankNationalCode: "000", text: "000 Banco Honda S.A." },
  { value: "57", bankNationalCode: "000", text: "000 Banco IBM S.A." },
  {
    value: "58",
    bankNationalCode: "012",
    text: "012 Banco INBURSA de Investimentos S.A."
  },
  {
    value: "59",
    bankNationalCode: "604",
    text: "604 Banco Industrial do Brasil S.A."
  },
  { value: "60", bankNationalCode: "653", text: "653 Banco Indusval S.A." },
  { value: "61", bankNationalCode: "077", text: "077 Banco Intermedium S.A." },
  {
    value: "62",
    bankNationalCode: "249",
    text: "249 Banco Investcred Unibanco S.A."
  },
  { value: "63", bankNationalCode: "184", text: "184 Banco Itaú BBA S.A." },
  {
    value: "64",
    bankNationalCode: "029",
    text: "029 Banco Itaú BMG Consignado S.A."
  },
  {
    value: "65",
    bankNationalCode: "000",
    text: "000 Banco Itaú Veículos S.A."
  },
  { value: "67", bankNationalCode: "376", text: "376 Banco J. P. Morgan S.A." },
  { value: "68", bankNationalCode: "074", text: "074 Banco J. Safra S.A." },
  { value: "69", bankNationalCode: "217", text: "217 Banco John Deere S.A." },
  {
    value: "70",
    bankNationalCode: "600",
    text: "600 Banco Luso Brasileiro S.A."
  },
  {
    value: "71",
    bankNationalCode: "389",
    text: "389 Banco Mercantil do Brasil S.A."
  },
  {
    value: "72",
    bankNationalCode: "370",
    text: "370 Banco Mizuho do Brasil S.A."
  },
  { value: "73", bankNationalCode: "746", text: "746 Banco Modal S.A." },
  { value: "74", bankNationalCode: "212", text: "212 Banco Original S.A." },
  { value: "75", bankNationalCode: "623", text: "623 Banco PAN S.A." },
  { value: "76", bankNationalCode: "611", text: "611 Banco Paulista S.A." },
  { value: "77", bankNationalCode: "094", text: "094 Banco Petra S.A." },
  { value: "78", bankNationalCode: "643", text: "643 Banco Pine S.A." },
  {
    value: "79",
    bankNationalCode: "000",
    text: "000 Banco PSA Finance Brasil S.A."
  },
  {
    value: "80",
    bankNationalCode: "747",
    text: "747 Banco Rabobank International Brasil S.A."
  },
  { value: "81", bankNationalCode: "633", text: "633 Banco Rendimento S.A." },
  { value: "82", bankNationalCode: "120", text: "120 Banco Rodobens S.A." },
  {
    value: "83",
    bankNationalCode: "366",
    text: "366 Banco Société Générale Brasil S.A."
  },
  {
    value: "84",
    bankNationalCode: "464",
    text: "464 Banco Sumitomo Mitsui Brasileiro S.A."
  },
  { value: "85", bankNationalCode: "082", text: "082 Banco Topázio S.A." },
  {
    value: "86",
    bankNationalCode: "000",
    text: "000 Banco Toyota do Brasil S.A."
  },
  { value: "87", bankNationalCode: "634", text: "634 Banco Triângulo S.A." },
  { value: "88", bankNationalCode: "000", text: "000 Banco Volkswagen S.A." },
  { value: "89", bankNationalCode: "000", text: "000 Banco Volvo Brasil S.A." },
  { value: "90", bankNationalCode: "655", text: "655 Banco Votorantim S.A." },
  { value: "91", bankNationalCode: "610", text: "610 Banco VR S.A." },
  {
    value: "92",
    bankNationalCode: "119",
    text: "119 Banco Western Union do Brasil S.A."
  },
  {
    value: "93",
    bankNationalCode: "021",
    text: "021 BANESTES S.A. Banco do Estado do Espírito Santo"
  },
  {
    value: "94",
    bankNationalCode: "719",
    text: "719 Banif-Banco Internacional do Funchal (Brasil)S.A."
  },
  {
    value: "95",
    bankNationalCode: "755",
    text: "755 Bank of America Merrill Lynch Banco Múltiplo S.A."
  },
  {
    value: "96",
    bankNationalCode: "081",
    text: "081 BBN Banco Brasileiro de Negócios S.A."
  },
  {
    value: "97",
    bankNationalCode: "250",
    text: "250 BCV - Banco de Crédito e Varejo S.A."
  },
  { value: "98", bankNationalCode: "017", text: "017 BNY Mellon Banco S.A." },
  {
    value: "99",
    bankNationalCode: "125",
    text: "125 Brasil Plural S.A. - Banco Múltiplo"
  },
  {
    value: "100",
    bankNationalCode: "070",
    text: "070 BRB - Banco de Brasília S.A."
  },
  {
    value: "101",
    bankNationalCode: "320",
    text: "320 China Construction Bank (Brasil) Banco Múltiplo S.A."
  },
  {
    value: "102",
    bankNationalCode: "487",
    text: "487 Deutsche Bank S.A. - Banco Alemão"
  },
  {
    value: "103",
    bankNationalCode: "064",
    text: "064 Goldman Sachs do Brasil Banco Múltiplo S.A."
  },
  {
    value: "104",
    bankNationalCode: "078",
    text: "078 Haitong Banco de Investimento do Brasil S.A."
  },
  {
    value: "105",
    bankNationalCode: "062",
    text: "062 Hipercard Banco Múltiplo S.A."
  },
  { value: "106", bankNationalCode: "492", text: "492 ING Bank N.V." },
  {
    value: "107",
    bankNationalCode: "652",
    text: "652 Itaú Unibanco Holding S.A."
  },
  {
    value: "108",
    bankNationalCode: "488",
    text: "488 JPMorgan Chase Bank, National Association"
  },
  {
    value: "109",
    bankNationalCode: "128",
    text: "128 MS Bank S.A. Banco de Câmbio"
  },
  { value: "110", bankNationalCode: "254", text: "254 Paraná Banco S.A." },
  {
    value: "111",
    bankNationalCode: "751",
    text: "751 Scotiabank Brasil S.A. Banco Múltiplo"
  },
  {
    value: "112",
    bankNationalCode: "118",
    text: "118 Standard Chartered Bank (Brasil) S/A–Bco Invest."
  },
  {
    value: "113",
    bankNationalCode: "129",
    text: "129 UBS Brasil Banco de Investimento S.A."
  },
  { value: "114", bankNationalCode: "756", text: "756 SICOOB" },
  { value: "115", bankNationalCode: "121", text: "121 Banco Agibank S.A" },
  {
    value: "116",
    bankNationalCode: "085",
    text: "085 Cooperativa Central de Crédito Urbano-CECRED"
  },
  { value: "117", bankNationalCode: "197", text: "197 Stone Pagamentos S.A" },
  { value: "118", bankNationalCode: "136", text: "136 Unicred do Brasil" },
  {
    value: "119",
    bankNationalCode: "260",
    text: "260 Nu Pagamentos S.A (Nubank)"
  },
  { value: "120", bankNationalCode: "133", text: "133 Cresol Confederação" },
  {
    value: "121",
    bankNationalCode: "099",
    text: "099 Cooperativa Central de Economia e Credito Mutuo das Unicreds"
  },
  { value: "122", bankNationalCode: "735", text: "735 Banco Neon S.A." },
  {
    value: "123",
    bankNationalCode: "084",
    text: "084 Unicred Norte do Paraná"
  },
  {
    value: "124",
    bankNationalCode: "089",
    text: "089 Cooperativa de Crédito Rural da Região da Mogiana"
  },
  { value: "125", bankNationalCode: "274", text: "274 Banco Money Plus" },
  {
    value: "126",
    bankNationalCode: "014",
    text: "014 Natixis Brasil S.A. Banco Múltiplo"
  },
  {
    value: "127",
    bankNationalCode: "015",
    text: "015 UBS Brasil Corretora de Câmbio"
  },
  {
    value: "128",
    bankNationalCode: "080",
    text: "080 BT Corretora de Câmbio Ltda."
  },
  {
    value: "129",
    bankNationalCode: "090",
    text: "090 Coop. Central de Econ. e Créd. Mútuo - Sicoob Unimais"
  },
  {
    value: "130",
    bankNationalCode: "091",
    text: "091 Central de Coop. de Econ. e Créd. Mútuo do Est RS - Unicred"
  },
  {
    value: "131",
    bankNationalCode: "093",
    text: "093 Pólocred Soc. Créd. Microempreendedor e à Emp. de Peq Porte"
  },
  {
    value: "132",
    bankNationalCode: "098",
    text: "098 Credialiança Cooperativa de Crédito Rural"
  },
  {
    value: "133",
    bankNationalCode: "100",
    text: "100 Planner Corretora de Valores S.A."
  },
  {
    value: "134",
    bankNationalCode: "101",
    text: "101 Renascença Distr. de Títulos e Valores Mobiliários Ltda."
  },
  {
    value: "135",
    bankNationalCode: "102",
    text: "102 XP Invest. Corret. de Câmbio Títulos e Valores Mobil. S.A."
  },
  { value: "136", bankNationalCode: "105", text: "105 Lecca Crédito" },
  { value: "137", bankNationalCode: "108", text: "108 PortoCred S.A. Crédito" },
  {
    value: "138",
    bankNationalCode: "111",
    text: "111 Oliveira Trust Distr. de Títulos e Valores Mobil. S.A."
  },
  {
    value: "139",
    bankNationalCode: "113",
    text: "113 Magliano S.A. Corretora de Cambio e Valores Mobiliarios"
  },
  {
    value: "140",
    bankNationalCode: "114",
    text: "114 Central das Coop. Econ. e Créd. Mútuo do Est do Esp. Santo"
  },
  {
    value: "141",
    bankNationalCode: "117",
    text: "117 Advanced Corretora de Câmbio Ltda."
  },
  {
    value: "143",
    bankNationalCode: "124",
    text: "124 Banco Woori Bank do Brasil S.A."
  },
  {
    value: "144",
    bankNationalCode: "126",
    text: "126 BR Partners Banco de Investimento S.A."
  },
  {
    value: "145",
    bankNationalCode: "127",
    text: "127 Codepe Corretora de Valores e Câmbio S.A."
  },
  {
    value: "146",
    bankNationalCode: "130",
    text: "130 Caruana S.A. Sociedade de Crédito"
  },
  {
    value: "147",
    bankNationalCode: "131",
    text: "131 Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda."
  },
  {
    value: "148",
    bankNationalCode: "132",
    text: "132 ICBC do Brasil Banco Múltiplo S.A."
  },
  {
    value: "149",
    bankNationalCode: "134",
    text: "134 BGC Liquidez Distribuidora de Títulos e Valores Mobil. Ltda."
  },
  {
    value: "150",
    bankNationalCode: "135",
    text: "135 Gradual Corretora de Câmbio"
  },
  {
    value: "151",
    bankNationalCode: "137",
    text: "137 Multimoney Corretora de Câmbio Ltda"
  },
  {
    value: "152",
    bankNationalCode: "138",
    text: "138 Get Money Corretora de Câmbio S.A."
  },
  {
    value: "153",
    bankNationalCode: "139",
    text: "139 Intesa Sanpaolo Brasil S.A. - Banco Múltiplo"
  },
  {
    value: "154",
    bankNationalCode: "140",
    text: "140 Easynvest - Título Corretora de Valores SA"
  },
  {
    value: "155",
    bankNationalCode: "142",
    text: "142 Broker Brasil Corretora de Câmbio Ltda."
  },
  {
    value: "156",
    bankNationalCode: "143",
    text: "143 Treviso Corretora de Câmbio S.A."
  },
  {
    value: "157",
    bankNationalCode: "144",
    text: "144 Bexs Banco de Câmbio S.A."
  },
  {
    value: "158",
    bankNationalCode: "145",
    text: "145 Levycam - Corretora de Câmbio e Valores Ltda."
  },
  {
    value: "159",
    bankNationalCode: "146",
    text: "146 Guitta Corretora de Câmbio Ltda."
  },
  {
    value: "160",
    bankNationalCode: "147",
    text: "147 Rico Corretora de Títulos e Valores Mobiliários S.A."
  },
  {
    value: "161",
    bankNationalCode: "149",
    text: "149 Facta Financeira S.A. - Crédito Financiamento e Invest"
  },
  {
    value: "162",
    bankNationalCode: "157",
    text: "157 ICAP do Brasil Corretora de Títulos e Valores Mobil. Ltda."
  },
  {
    value: "163",
    bankNationalCode: "159",
    text: "159 Casa do Crédito S.A. Soc. de Créd. ao Microempreendedor"
  },
  {
    value: "164",
    bankNationalCode: "163",
    text: "163 Commerzbank Brasil S.A. - Banco Múltiplo"
  },
  {
    value: "165",
    bankNationalCode: "167",
    text: "167 S. Hayata Corretora de Câmbio S.A."
  },
  {
    value: "166",
    bankNationalCode: "172",
    text: "172 Albatross Corretora de Câmbio e Valores S.A"
  },
  {
    value: "167",
    bankNationalCode: "173",
    text: "173 BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A."
  },
  {
    value: "168",
    bankNationalCode: "177",
    text: "177 Guide Investimentos S.A. Corretora de Valores"
  },
  {
    value: "169",
    bankNationalCode: "180",
    text: "180 CM Capital Markets Corretora de Câmbio"
  },
  {
    value: "170",
    bankNationalCode: "182",
    text: "182 Dacasa Financeira S/A - Sociedade de Crédito"
  },
  {
    value: "171",
    bankNationalCode: "183",
    text: "183 Socred S.A. - Sociedade de Crédito ao Microempreendedor"
  },
  {
    value: "172",
    bankNationalCode: "188",
    text: "188 Ativa Investimentos S.A. Corret. de Tít. Câmbio e Valores"
  },
  {
    value: "173",
    bankNationalCode: "189",
    text: "189 HS Financeira S/A Crédito"
  },
  {
    value: "174",
    bankNationalCode: "191",
    text: "191 Nova Futura Corretora de Títulos e Valores Mobil. Ltda."
  },
  {
    value: "175",
    bankNationalCode: "196",
    text: "196 Fair Corretora de Câmbio S.A."
  },
  { value: "176", bankNationalCode: "213", text: "213 Banco Arbi S.A." },
  { value: "177", bankNationalCode: "241", text: "241 Banco Clássico S.A." },
  { value: "178", bankNationalCode: "243", text: "243 Banco Máxima S.A." },
  {
    value: "179",
    bankNationalCode: "253",
    text: "253 Bexs Corretora de Câmbio S/A"
  },
  { value: "180", bankNationalCode: "266", text: "266 Banco Cédula S.A." },
  {
    value: "181",
    bankNationalCode: "300",
    text: "300 Banco de la Nacion Argentina"
  },
  { value: "182", bankNationalCode: "412", text: "412 Banco Capital S. A." },
  {
    value: "183",
    bankNationalCode: "494",
    text: "494 Banco de La Republica Oriental del Uruguay"
  },
  {
    value: "184",
    bankNationalCode: "495",
    text: "495 Banco de La Provincia de Buenos Aires"
  },
  {
    value: "185",
    bankNationalCode: "545",
    text: "545 Senso Corretora de Câmbio e Valores Mobiliários S.A."
  },
  { value: "186", bankNationalCode: "613", text: "613 Banco Pecúnia S. A." },
  { value: "187", bankNationalCode: "630", text: "630 Banco Intercap S.A." },
  { value: "188", bankNationalCode: "637", text: "637 Banco Sofisa S. A." },
  {
    value: "189",
    bankNationalCode: "654",
    text: "654 Banco A. J. Renner S.A."
  },
  { value: "190", bankNationalCode: "712", text: "712 Banco Ourinvest S.A." },
  {
    value: "191",
    bankNationalCode: "741",
    text: "741 Banco Ribeirão Preto S.A."
  },
  { value: "192", bankNationalCode: "743", text: "743 Banco Semear S.A." },
  {
    value: "193",
    bankNationalCode: "753",
    text: "753 Novo Banco Continental S.A. - Banco Múltiplo"
  },
  { value: "194", bankNationalCode: "754", text: "754 Banco Sistema S.A." },
  {
    value: "195",
    bankNationalCode: "757",
    text: "757 Banco Keb Hana do Brasil S. A."
  },
  {
    value: "196",
    bankNationalCode: "097",
    text: "097 Cooperativa Central de Crédito Noroeste Brasileiro Ltda"
  },
  { value: "197", bankNationalCode: "336", text: "336 Banco C6 S.A - C6 Bank" },
  { value: "198", bankNationalCode: "290", text: "290 PagSeguro Internet S.A" },
  {
    value: "199",
    bankNationalCode: "340",
    text: "340 Super Pagamentos e Administração de Meios Eletrônicos S.A."
  },
  {
    value: "200",
    bankNationalCode: "364",
    text: "364 Gerencianet Pagamentos do Brasil"
  },
  { value: "201", bankNationalCode: "323", text: "323 MERCADO PAGO" },
  { value: "202", bankNationalCode: "403", text: "403 CORA SCD S.A." },
  {
    value: "203",
    bankNationalCode: "032",
    text: "032 BANCO DO ESTADO DO MATO GROSSO S/A"
  },
  { value: "204", bankNationalCode: "380", text: "380 PICPAY" },
  {
    value: "205",
    bankNationalCode: "288",
    text: "288 Sorocred - Crédito, Financiamento e Investimento S"
  },
  { value: "206", bankNationalCode: "007", text: "007 BNDES" },
  { value: "207", bankNationalCode: "010", text: "010 CREDICOAMO" },
  {
    value: "208",
    bankNationalCode: "011",
    text: "011 C.SUISSE HEDGING-GRIFFO CV S/A"
  },
  { value: "209", bankNationalCode: "016", text: "016 CCM DESP TRÂNS SC E RS" },
  { value: "210", bankNationalCode: "018", text: "018 BCO TRICURY S.A." },
  { value: "211", bankNationalCode: "060", text: "060 CONFIDENCE CC S.A." },
  {
    value: "212",
    bankNationalCode: "066",
    text: "066 BCO MORGAN STANLEY S.A."
  },
  { value: "213", bankNationalCode: "076", text: "076 BCO KDB BRASIL S.A." },
  {
    value: "214",
    bankNationalCode: "079",
    text: "079 BCO ORIGINAL DO AGRO S/A"
  },
  { value: "215", bankNationalCode: "088", text: "088 BANCO RANDON S.A." },
  { value: "216", bankNationalCode: "092", text: "092 BRK S.A. CFI" },
  { value: "217", bankNationalCode: "132", text: "132 ICBC DO BRASIL BM S.A." },
  {
    value: "218",
    bankNationalCode: "139",
    text: "139 INTESA SANPAOLO BRASIL S.A. BM"
  },
  { value: "219", bankNationalCode: "174", text: "174 PEFISA S.A. - CFI" },
  { value: "220", bankNationalCode: "190", text: "190 SERVICOOP" },
  { value: "221", bankNationalCode: "194", text: "194 PARMETAL DTVM LTDA" },
  {
    value: "222",
    bankNationalCode: "259",
    text: "259 MONEYCORP BCO DE CÂMBIO S.A."
  },
  { value: "223", bankNationalCode: "268", text: "268 BARI CIA HIPOTECÁRIA" },
  { value: "225", bankNationalCode: "270", text: "270 SAGITUR CC LTDA" },
  { value: "226", bankNationalCode: "271", text: "271 IB CCTVM S.A." },
  { value: "227", bankNationalCode: "272", text: "272 AGK CC S.A." },
  {
    value: "228",
    bankNationalCode: "273",
    text: "273 CCR DE SÃO MIGUEL DO OESTE"
  },
  { value: "229", bankNationalCode: "276", text: "276 SENFF S.A. - CFI" },
  {
    value: "230",
    bankNationalCode: "278",
    text: "278 GENIAL INVESTIMENTOS CVM S.A."
  },
  {
    value: "231",
    bankNationalCode: "279",
    text: "279 CCR DE PRIMAVERA DO LESTE"
  },
  { value: "232", bankNationalCode: "280", text: "280 AVISTA S.A. CFI" },
  { value: "233", bankNationalCode: "281", text: "281 CCR COOPAVEL" },
  {
    value: "234",
    bankNationalCode: "283",
    text: "283 RB CAPITAL INVESTIMENTOS DTVM LTDA."
  },
  { value: "235", bankNationalCode: "285", text: "285 FRENTE CC LTDA." },
  { value: "236", bankNationalCode: "286", text: "286 CCR DE OURO" },
  { value: "237", bankNationalCode: "288", text: "288 CAROL DTVM LTDA." },
  { value: "238", bankNationalCode: "289", text: "289 DECYSEO CC LTDA." },
  { value: "239", bankNationalCode: "292", text: "292 BS2 DTVM S.A." },
  { value: "240", bankNationalCode: "293", text: "293 LASTRO RDV DTVM LTDA" },
  { value: "241", bankNationalCode: "296", text: "296 VISION S.A. CC" },
  { value: "242", bankNationalCode: "298", text: "298 VIPS CC LTDA." },
  { value: "243", bankNationalCode: "299", text: "299 SOROCRED CFI S.A." },
  { value: "244", bankNationalCode: "301", text: "301 BPP IP S.A." },
  { value: "245", bankNationalCode: "306", text: "306 PORTOPAR DTVM LTDA" },
  {
    value: "246",
    bankNationalCode: "307",
    text: "307 TERRA INVESTIMENTOS DTVM"
  },
  { value: "247", bankNationalCode: "309", text: "309 CAMBIONET CC LTDA" },
  { value: "248", bankNationalCode: "310", text: "310 VORTX DTVM LTDA." },
  { value: "249", bankNationalCode: "313", text: "313 AMAZÔNIA CC LTDA." },
  { value: "250", bankNationalCode: "315", text: "315 PI DTVM S.A." },
  { value: "251", bankNationalCode: "319", text: "319 OM DTVM LTDA" },
  { value: "252", bankNationalCode: "321", text: "321 CREFAZ SCMEPP LTDA" },
  { value: "253", bankNationalCode: "322", text: "322 CCR DE ABELARDO LUZ" },
  { value: "254", bankNationalCode: "324", text: "324 CARTOS SCD S.A." },
  { value: "255", bankNationalCode: "325", text: "325 ÓRAMA DTVM S.A." },
  { value: "256", bankNationalCode: "326", text: "326 PARATI - CFI S.A." },
  { value: "257", bankNationalCode: "329", text: "329 QI SCD S.A." },
  { value: "258", bankNationalCode: "330", text: "330 BANCO BARI S.A." },
  { value: "259", bankNationalCode: "331", text: "331 FRAM CAPITAL DTVM S.A." },
  {
    value: "260",
    bankNationalCode: "332",
    text: "332 ACESSO SOLUCOES PAGAMENTO SA"
  },
  { value: "261", bankNationalCode: "335", text: "335 BANCO DIGIO" },
  { value: "262", bankNationalCode: "342", text: "342 CREDITAS SCD" },
  { value: "263", bankNationalCode: "343", text: "343 FFA SCMEPP LTDA." },
  { value: "264", bankNationalCode: "348", text: "348 BCO XP S.A." },
  { value: "265", bankNationalCode: "349", text: "349 AL5 S.A. CFI" },
  { value: "266", bankNationalCode: "350", text: "350 CREHNOR LARANJEIRAS" },
  { value: "267", bankNationalCode: "352", text: "352 TORO CTVM LTDA" },
  {
    value: "268",
    bankNationalCode: "354",
    text: "354 NECTON INVESTIMENTOS S.A CVM"
  },
  { value: "269", bankNationalCode: "355", text: "355 ÓTIMO SCD S.A." },
  { value: "270", bankNationalCode: "359", text: "359 ZEMA CFI S/A" },
  { value: "271", bankNationalCode: "360", text: "360 TRINUS CAPITAL DTVM" },
  { value: "272", bankNationalCode: "362", text: "362 CIELO S.A." },
  { value: "273", bankNationalCode: "363", text: "363 SINGULARE CTVM S.A." },
  { value: "274", bankNationalCode: "365", text: "365 SOLIDUS S.A. CCVM" },
  { value: "275", bankNationalCode: "367", text: "367 VITREO DTVM S.A." },
  { value: "276", bankNationalCode: "368", text: "368 BCO CSF S.A." },
  { value: "277", bankNationalCode: "371", text: "371 WARREN CVMC LTDA" },
  { value: "278", bankNationalCode: "373", text: "373 UP.P SEP S.A." },
  { value: "279", bankNationalCode: "374", text: "374 REALIZE CFI S.A." },
  { value: "280", bankNationalCode: "377", text: "377 BMS SCD S.A." },
  { value: "281", bankNationalCode: "378", text: "378 BBC LEASING" },
  { value: "282", bankNationalCode: "379", text: "379 CECM COOPERFORTE" },
  { value: "283", bankNationalCode: "381", text: "381 BCO MERCEDES-BENZ S.A." },
  { value: "284", bankNationalCode: "382", text: "382 FIDUCIA SCMEPP LTDA" },
  { value: "285", bankNationalCode: "383", text: "383 JUNO" },
  { value: "286", bankNationalCode: "384", text: "384 GLOBAL SCM LTDA" },
  {
    value: "287",
    bankNationalCode: "387",
    text: "387 BCO TOYOTA DO BRASIL S.A."
  },
  { value: "288", bankNationalCode: "390", text: "390 BCO GM S.A." },
  { value: "289", bankNationalCode: "391", text: "391 CCR DE IBIAM" },
  { value: "290", bankNationalCode: "393", text: "393 BCO VOLKSWAGEN S.A" },
  { value: "291", bankNationalCode: "395", text: "395 F D GOLD DTVM LTDA" },
  { value: "292", bankNationalCode: "396", text: "396 HUB PAGAMENTOS" },
  { value: "293", bankNationalCode: "397", text: "397 LISTO SCD S.A." },
  { value: "294", bankNationalCode: "402", text: "402 COBUCCIO SCD S.A." },
  { value: "295", bankNationalCode: "404", text: "404 SUMUP SCD S.A." },
  { value: "296", bankNationalCode: "406", text: "406 ACCREDITO SCD S.A." },
  { value: "297", bankNationalCode: "408", text: "408 BÔNUSCRED SCD S.A." },
  { value: "298", bankNationalCode: "450", text: "450 FitBank" },
  {
    value: "299",
    bankNationalCode: "000",
    text: "000 Magalu Pagamentos Ltda."
  },
  { value: "0", bankNationalCode: "000", text: "000 Outros" }
];
