
import Vue from "vue";
import Component from "vue-class-component";
import dayjs from "dayjs";
import LoanService from "@/services/loan-service";
import Loan from "@/types/loan";
import { Prop } from "vue-property-decorator";
import { ILoanStatusHistoryWithUsers } from "@/types/loan-status-history";
import SafetyService from "@/services/safety-service";
import { LoanStatusEnum } from "@/types/loan-status";

@Component
export default class LoanHistoryModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  service: LoanService;
  loanHistory: ILoanStatusHistoryWithUsers[] = [];
  loading: boolean = true;
  headers = [
    { text: "Data Ocorrência", value: "date" },
    { text: "Evento", value: "status" },
    { text: "Tipo de Usuário", value: "user.type" },
    { text: "Usuário", value: "user.email" }
  ];
  safetyService: SafetyService;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.safetyService = SafetyService.getInstance();
  }

  created() {
    this.fetchLoanHistory();
  }

  async fetchLoanHistory() {
    this.loading = true;

    const [loanHistoryError, loanHistory] =
      await this.service.getLoanHistoryByLoanId(this.item.id);

    if (loanHistoryError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar o histórico do empréstimo."
      });
      this.loading = false;
      return;
    }

    const thirdPartySimulationLoanHistory = loanHistory.filter(
      (item) => item.statusId === LoanStatusEnum.THIRD_PARTY_SIMULATION.id
    );

    const maxIdHistory = thirdPartySimulationLoanHistory.reduce((max, item) => {
      return item.id > max.id ? item : max;
    }, thirdPartySimulationLoanHistory[0]);

    this.loanHistory = loanHistory.filter(
      (item) => item.statusId !== LoanStatusEnum.THIRD_PARTY_SIMULATION.id
    );
    if (maxIdHistory) {
      this.loanHistory.push(maxIdHistory);
    }

    this.loanHistory.sort((a, b) => b.id - a.id);

    this.loading = false;
  }

  formatDate(date: Date): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  formatDateWithHours(date: string): string {
    return dayjs(date).format("DD/MM/YYYY - HH:mm");
  }
}
