var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"value":_vm.show,"max-width":"550px"},on:{"click:outside":function($event){return _vm.$emit('close')}}},[_c('v-card',{staticClass:"py-6"},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Recusar o empréstimo "+_vm._s(_vm.item?.id)+"? ")]),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mr-3",attrs:{"size":"18","indeterminate":"","left":""}})],1):(_vm.nonEndorsementMotives && _vm.nonEndorsementMotives.length)?_c('v-card-text',{},[_c('LoanInfo',{attrs:{"item":_vm.item}}),_c('v-select',{attrs:{"hide-details":"auto","outlined":"","items":_vm.nonEndorsementMotives,"item-value":"id","item-text":"readableName","label":"Selecione o motivo"},model:{value:(_vm.nonEndorsementMotiveId),callback:function ($$v) {_vm.nonEndorsementMotiveId=$$v},expression:"nonEndorsementMotiveId"}}),_c('br'),_c('br'),(
          _vm.nonEndorsementMotiveId ===
          _vm.nonEndorsementMotivesIdsEnum.OTHERS_REASONS
        )?_c('VTextAreaWithValidation',{attrs:{"rules":"max:255","outlined":"","rows":"2","label":"Observação","single-line":""},model:{value:(_vm.endorsementObservation),callback:function ($$v) {_vm.endorsementObservation=$$v},expression:"endorsementObservation"}}):_vm._e(),(
          _vm.nonEndorsementMotiveId ===
          _vm.nonEndorsementMotivesIdsEnum.MARGIN_GREATER_THAN_ALLOWED
        )?_c('vuetify-money',{attrs:{"label":"Valor de margem disponível","options":{ prefix: 'R$ ', precision: 2 }},model:{value:(_vm.nonEndorsementAvailableMargin),callback:function ($$v) {_vm.nonEndorsementAvailableMargin=$$v},expression:"nonEndorsementAvailableMargin"}}):_vm._e()],1):_vm._e(),(_vm.nonEndorsementMotiveId && !_vm.loading)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"px-6",attrs:{"color":"primary","large":""},on:{"click":_vm.handleRefuse}},[_vm._v("Confirmar")]),_c('v-spacer')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }