
import Loan from "@/types/loan";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LoanUndoFiredEmployeeModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;

  constructor() {
    super();
  }

  handleUndoFiredEmployee(): void {
    this.$emit("input", this.item);
  }
}
