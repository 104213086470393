
import Vue from "vue";
import CloseBtn from "@/components/CloseBtn.vue";
import PdfViewer from "@/components/PdfViewer.vue";
export default Vue.extend({
  components: {
    CloseBtn,
    PdfViewer
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    showAcceptActions: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Termos de Aceite"
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    large: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      termsAccepted: false
    };
  },
  watch: {
    value() {
      this.termsAccepted = !!this.value;
    }
  },
  methods: {
    changed() {
      this.$emit("input", this.termsAccepted);
      this.dialog = false;
    }
  }
});
