<template>
  <v-stepper
    v-if="loan && statusHistory && statusHistory.length"
    alt-labels
    class="status-history elevation-0"
    :value="statusHistory.length"
  >
    <v-stepper-header>
      <template v-for="(step, i) in statusHistory">
        <v-stepper-step
          :key="'step-' + i"
          :color="stepColor(step)"
          :step="i + 1"
          :complete="i + 1 < statusHistory.length"
        >
          {{ step.status.description }}
          <div
            v-if="
              step.status.name === 'RECUSADO_AVERBACAO' &&
              loan.nonEndorsementMotive
            "
            class="pt-1"
          >
            <small class="caption red--text">
              ({{ loan.nonEndorsementMotive.readableName }})
            </small>
          </div>
          <div v-if="step.date" class="pt-1">
            <small class="caption">{{ formatDate(step.date) }}</small>
          </div>
          <div v-if="isAdminGooroo && step.observation" class="pt-1">
            <small class="caption"> ({{ step.observation }}) </small>
          </div>
        </v-stepper-step>
        <v-divider
          v-if="i < statusHistory.length - 1"
          :key="'divider-' + i"
        ></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import dayjs from "@/plugins/day-js";
export default {
  props: {
    loan: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    statusHistory() {
      const items = this.loan.statusHistory || [];
      return items.sort(this.sortStatusHistory);
    },
    isAdminGooroo() {
      return (
        this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
      );
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm");
    },
    stepColor(step) {
      if (
        step.status.name.includes("CANCELADO") ||
        step.status.name.includes("RECUSADO")
      ) {
        return "red";
      }
      if (step.status.name === "PAGO") {
        return "green";
      }
    },
    sortStatusHistory(a, b) {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      // a deve ser igual a b
      return 0;
    }
  }
};
</script>
<style lang="scss">
.status-history.v-stepper {
  background-color: transparent !important;
  .v-stepper__header {
    background-color: transparent;
    @media (max-width: 1264px) {
      display: block;
    }
  }
  .v-stepper__label {
    display: block !important;
    text-align: center;
    color: #485cc766 !important;
    font-size: 14px;
    line-height: 1.5;
  }

  .v-divider {
    border: 6px solid #fff !important;
    margin-top: 52px !important;
    @media (max-width: 1264px) {
      display: none;
    }
  }

  .v-stepper__step {
    .v-stepper__step__step {
      background-color: #fff !important;
      color: transparent !important;
      border: 10px solid #fff;
      // background-color: transparent !important;
      height: 68px;
      width: 68px;
      .v-icon {
        display: none;
      }
    }
    &.v-stepper__step--complete {
      .v-stepper__step__step {
        background-color: #485cc766 !important;
        border-color: #fff !important;
      }
    }
  }
  .v-stepper__step--active {
    .v-stepper__step__step {
      background-color: #485cc7 !important;
      border-color: #fff !important;
      &.red {
        background-color: #f44336 !important;
      }
      &.green {
        background-color: #4caf50 !important;
      }
    }
    .v-stepper__label {
      color: #485cc7 !important;
      text-shadow: none;
    }
  }
}
</style>
