export default interface RefinancingStatus {
  id: number;

  name: string;
  readableName: string;

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

const openStatus: RefinancingStatus = {} as RefinancingStatus;
openStatus.id = 1;
openStatus.name = "EM_ABERTO";
openStatus.readableName = "Em aberto";

const paidStatus: RefinancingStatus = {} as RefinancingStatus;
paidStatus.id = 2;
paidStatus.name = "PAGO";
paidStatus.readableName = "Pago";

const canceledStatus: RefinancingStatus = {} as RefinancingStatus;
canceledStatus.id = 3;
canceledStatus.name = "CANCELADO";
canceledStatus.readableName = "Cancelado";

export const RefinancingStatusEnum = {
  OPEN: openStatus,
  PAID: paidStatus,
  CANCELED: canceledStatus
};
