
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import AlertBox from "@/components/AlertBox.vue";
import Modal from "@/components/Modal.vue";
import SaveButton from "@/components/SaveButton.vue";
import LoanService, { SaveLoanResponse } from "@/services/loan-service";
import ILoanType, { LoanTypeEnum } from "@/types/loan-type";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import CompanyService from "@/services/company-service";
import Products from "@/types/products";
import ConsignetDataSelection from "@/components/margin-bases/ConsignetDataSelection.vue";
import MarginBaseService, {
  MarginBaseRegistersGroupedByCompany
} from "../../services/margin-base-service";
import Company from "../../types/company";
import { checkIfLoanFlowCacheIsRecent } from "../../utils/checkIfLoanFlowCacheIsRecent";
import { format as formatCNPJ } from "@/utils/cnpj";
import dayjs from "dayjs";
import formatCurrency from "../../utils/formatCurrency";
import { getBankingEnumNameById } from "../../types/BankingEnum";
import { getAdministratorEnumNameById } from "../../types/AdministratorEnum";

interface State {
  form: any;
  marginBaseRegisters: MarginBaseRegistersGroupedByCompany[];
  shouldShowConsignetDataSelection: boolean;
  consignetRelatedCompanies: Company[];
  productsByCompany: { [companyId: number]: Array<Partial<Products>> };
}

interface Cache extends State {
  _cacheDate: Date;
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    SaveButton,
    AlertBox,
    ConsignetDataSelection
  },
  directives: { mask }
})
export default class LoanType extends Vue {
  @Prop() readonly data!: SaveLoanResponse;
  @Prop({ type: Object, default: () => ({}) }) cache!: Cache;
  /** Used to preserve state (i.e., all relevant data) during re-renders caused by reactive behavior */
  @Prop() readonly editState!: State;
  /** Used on component creation to avoid treating programmatic changes to form as if they were actual user input */
  isProgrammaticFormInput: boolean = false;
  LoanTypeEnum = LoanTypeEnum;
  loading: boolean = false;
  loanService: LoanService;
  companyService: CompanyService;
  marginBaseService: MarginBaseService;
  success: boolean = false;
  loadingMarginBaseRegisters = false;
  marginBaseRegisters: MarginBaseRegistersGroupedByCompany[] = [];
  shouldShowConsignetDataSelection: boolean = false;
  consignetRelatedCompanies: Company[] = [];
  productsByCompany: { [companyId: number]: Array<Partial<Products>> } = {};
  form = {
    marginBaseRegister: {
      id: this.editState?.form?.marginBaseRegister?.id || 0
    } as MarginBaseRegistersGroupedByCompany,
    withInsurance: this.data.loan.withInsurance,
    numInstallments: this.data.loan.numInstallments || 6,
    minInstallments: 6,
    maxInstallments: 6,
    loanTypeId: this.data.loan.typeId,
    companyId: this.data.loan.companyId,
    bankingId: 1,
    bankingName: "",
    administratorId: 1,
    administratorName: ""
  };

  constructor() {
    super();

    this.loanService = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
  }

  @Watch("form", { deep: true })
  async formChanged(): Promise<void> {
    this.onUnsavedChanges();
  }

  @Watch("form.loanTypeId")
  async loanTypeIdChanged(): Promise<void> {
    this.updateBankingAndAdministratorDataBasedOnProducts();
  }

  async mounted(): Promise<void> {
    if (!isEmpty(this.editState)) {
      this.restoreDataFrom(this.editState);
    } else {
      if (this.isCacheValid()) {
        this.restoreDataFrom(this.cache);
      } else {
        await this.loadMarginBaseRegisters();
        await this.loadCacheableData();
      }
      this.isProgrammaticFormInput = true;

      // Programmatic changes on component creation go here

      this.$nextTick(() => {
        this.isProgrammaticFormInput = false;
      });
    }
  }

  async loadCacheableData(): Promise<void> {
    // Cacheable data load goes here
  }

  isCacheValid(): boolean {
    const isCacheRecent = checkIfLoanFlowCacheIsRecent(this.cache?._cacheDate);

    return (
      isCacheRecent &&
      !isEmpty(this.cache) &&
      !isEmpty(this.cache.form) &&
      !isEmpty(this.cache.marginBaseRegisters) &&
      !isEmpty(this.cache.productsByCompany)
    );
  }

  saveCache(): void {
    const data: Cache = {
      _cacheDate: new Date(),
      form: this.form,
      marginBaseRegisters: this.marginBaseRegisters,
      shouldShowConsignetDataSelection: this.shouldShowConsignetDataSelection,
      consignetRelatedCompanies: this.consignetRelatedCompanies,
      productsByCompany: this.productsByCompany
    };
    this.$emit("loadData", cloneDeep(data));
  }

  async onSaveConsignetDataSelection(): Promise<void> {
    this.shouldShowConsignetDataSelection = false;
    await this.loadMarginBaseRegisters();
  }

  async loadMarginBaseRegisters(): Promise<void> {
    this.loadingMarginBaseRegisters = true;
    this.marginBaseRegisters = [];
    const [marginBaseRegisterError, marginBaseRegistersData] =
      await this.marginBaseService.listMarginBaseRegistersGroupedByCompany(
        this.data.borrower.cpf.replace(/\D/g, "")
      );

    if (marginBaseRegisterError) {
      const thereIsNoMarginBaseRegisterForThisBorrower =
        marginBaseRegisterError.response?.status === 404;
      if (thereIsNoMarginBaseRegisterForThisBorrower) {
        const [
          borrowerCompanyRelatedConsignetPartnershipsError,
          borrowerCompanyRelatedConsignetPartnerships
        ] =
          await this.marginBaseService.findCompanyRelatedConsignetPartnerships(
            this.data.borrower.cpf.replace(/\D/g, "")
          );

        if (!borrowerCompanyRelatedConsignetPartnerships?.length) {
          this.loadingMarginBaseRegisters = false;
          return;
        } else {
          this.consignetRelatedCompanies =
            borrowerCompanyRelatedConsignetPartnerships;
          this.shouldShowConsignetDataSelection = true;
        }
      } else {
        this.loadingMarginBaseRegisters = false;
        return this.$notify({
          title: "Erro",
          text: "Não foi possível carregar os vínculos empregatícios e margem base para este CPF.",
          type: "error"
        });
      }
    }

    if (marginBaseRegistersData) {
      this.marginBaseRegisters = marginBaseRegistersData;

      const [productError, productData] =
        await this.companyService.findProductsByCompanies(
          this.marginBaseRegisters.map((mbr) => mbr.company.id)
        );

      if (productError) {
        return this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(productError)
        });
      }

      if (productData) {
        this.productsByCompany = productData;

        // Handle margin base register selection
        if (this.data.marginBaseRegister?.id) {
          const existingMarginBaseRegisterSelection =
            this.marginBaseRegisters.find(
              (mbr) => mbr.id === this.data.marginBaseRegister.id
            );

          if (existingMarginBaseRegisterSelection)
            this.form.marginBaseRegister.id =
              existingMarginBaseRegisterSelection.id;
        } else {
          // Always select a company
          this.form.marginBaseRegister.id = this.marginBaseRegisters[0].id;
          await this.updateLoanMarginBaseRegister();
        }
      }
    }
    this.loadingMarginBaseRegisters = false;
  }

  async updateLoanMarginBaseRegister(): Promise<void> {
    this.startLoading();

    const [loanError, updatedLoan] =
      await this.loanService.saveLoanMarginBaseRegister({
        cpf: this.data.borrower.cpf,
        data: {
          marginBaseRegister: {
            id: this.form.marginBaseRegister?.id
          }
        }
      });

    if (loanError) {
      this.stopLoading();
      this.$notify({
        title: "Erro",
        text: "Erro ao selecionar base de margem.",
        type: "error"
      });
      return;
    }

    if (updatedLoan) {
      this.$notify({
        title: "Sucesso",
        text: "Base de margem selecionada com sucesso.",
        type: "success"
      });

      this.saveCache();
      this.$emit("updatedLoan", updatedLoan);
    }

    this.stopLoading();
  }

  getProductsForCompanyId(companyId: number): Array<Partial<Products>> {
    return this.productsByCompany[companyId] || [];
  }

  get loanTypes(): ILoanType[] {
    const allLoanTypes = Object.values(LoanTypeEnum);

    if (!this.selectedMarginBaseRegister) return allLoanTypes;

    const products = this.getProductsForCompanyId(
      this.selectedMarginBaseRegister.company.id
    );

    return allLoanTypes.filter((type) =>
      products.find((product) => product.typeId === type.id && product.isActive)
    );
  }

  get marginBaseRegistersList(): { value: number; text: string }[] {
    return this.marginBaseRegisters.map((marginBaseRegister) => ({
      value: marginBaseRegister.id,
      text:
        marginBaseRegister.company.name +
        " - " +
        formatCNPJ(marginBaseRegister.company.cnpj)
    }));
  }

  get selectedMarginBaseRegister(): {
    admissionDate: string;
    companyTime: string;
    liquidAmount: string;
    occupation: string;
    company: Pick<Company, "id" | "name" | "cnpj">;
  } | null {
    if (this.marginBaseRegistersList.length) {
      const selected = this.marginBaseRegisters.find(
        (marginBaseRegister: MarginBaseRegistersGroupedByCompany) => {
          return marginBaseRegister.id === this.form.marginBaseRegister.id;
        }
      );

      if (selected) {
        const [matchingProduct] = this.getProductsForCompanyId(
          selected.company.id
        ).filter(
          (product) =>
            product.typeId === this.data.loan.typeId && product.isActive
        );

        if (matchingProduct) {
          this.form.bankingId = matchingProduct.bankingId;
          this.form.bankingName = getBankingEnumNameById(
            matchingProduct.bankingId
          );
          this.form.administratorId = matchingProduct.adminId;
          this.form.administratorName = getAdministratorEnumNameById(
            matchingProduct.adminId
          );
        } else {
          this.form.loanTypeId = null;
        }

        return {
          admissionDate: dayjs(selected.admissionDate).format("DD/MM/YYYY"),
          companyTime: dayjs().diff(selected.admissionDate, "year") + " anos",
          liquidAmount: formatCurrency(Number(selected.liquidIncome)),
          occupation: selected.occupation,
          company: selected.company
        };
      }
    }
    return null;
  }

  async updateBankingAndAdministratorDataBasedOnProducts(): Promise<void> {
    const selected = this.marginBaseRegisters.find(
      (marginBaseRegister: MarginBaseRegistersGroupedByCompany) => {
        return marginBaseRegister.id === this.form.marginBaseRegister.id;
      }
    );

    if (selected) {
      const [matchingProduct] = this.getProductsForCompanyId(
        selected.company.id
      ).filter(
        (product) => product.typeId === this.form.loanTypeId && product.isActive
      );

      if (matchingProduct) {
        this.form.bankingId = matchingProduct.bankingId;
        this.form.bankingName = getBankingEnumNameById(
          matchingProduct.bankingId
        );
        this.form.administratorId = matchingProduct.adminId;
        this.form.administratorName = getAdministratorEnumNameById(
          matchingProduct.adminId
        );
      } else {
        this.form.loanTypeId = null;
      }
    }
  }

  async save(): Promise<void> {
    this.loading = true;
    if (this.form.loanTypeId === LoanTypeEnum.SALARY_ANTICIPATION.id) {
      this.form.withInsurance = false;
      this.form.numInstallments = 1;
      this.form.minInstallments = 1;
      this.form.maxInstallments = 1;
    } else {
      this.form.withInsurance = true;
    }

    const [loanError, updatedLoan] = await this.loanService.saveLoanType({
      cpf: this.data.borrower.cpf,
      data: {
        loan: {
          type: Object.values(LoanTypeEnum).find(
            (type) => type.id === this.form.loanTypeId
          )?.name,
          numInstallments: this.form.numInstallments,
          withInsurance: this.form.withInsurance,
          bankingId: this.form.bankingId,
          bankingName: this.form.bankingName,
          administratorId: this.form.administratorId,
          administratorName: this.form.administratorName
        }
      }
    });

    if (loanError) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(loanError),
        type: "error"
      });
      return;
    }

    if (updatedLoan) {
      this.$notify({
        title: "Sucesso",
        text: "Tipo de empréstimo salvo com sucesso.",
        type: "success"
      });

      this.saveCache();
      this.$emit("updatedLoan", updatedLoan);
      this.goToNextStep();
    }

    this.loading = false;
  }

  onUnsavedChanges(): void {
    if (!this.isProgrammaticFormInput) {
      this.$emit("unsavedChanges");
    }
    const data: State = {
      form: this.form,
      marginBaseRegisters: this.marginBaseRegisters,
      shouldShowConsignetDataSelection: this.shouldShowConsignetDataSelection,
      consignetRelatedCompanies: this.consignetRelatedCompanies,
      productsByCompany: this.productsByCompany
    };
    this.$emit("updateEditState", data);
  }

  restoreDataFrom(data: State | Cache): void {
    this.form = cloneDeep(data.form);
    this.marginBaseRegisters = cloneDeep(data.marginBaseRegisters);
    this.shouldShowConsignetDataSelection = cloneDeep(
      data.shouldShowConsignetDataSelection
    );
    this.consignetRelatedCompanies = cloneDeep(data.consignetRelatedCompanies);
    this.productsByCompany = cloneDeep(data.productsByCompany);
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  goToPreviousStep(): void {
    this.$emit("previous");
  }

  goToNextStep(): void {
    this.$emit("next");
  }
}
