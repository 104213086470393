
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";

@Component({
  components: { ValidationObserver }
})
export default class LoanCancelModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  cancelationMotive: string = "";

  handleCancel() {
    this.$emit("input", {
      ...this.item,
      cancelationMotive: this.cancelationMotive
    });
  }
}
