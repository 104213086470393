<template>
  <div class="slider-input">
    <div class="d-flex justify-center text-center">
      <div>
        <div v-if="label" class="mb-3">{{ label }}</div>
        <vuetify-money
          v-model="amount"
          outlined
          :options="{
            prefix: maskOptions.symbol,
            precision: maskOptions.fractionCount,
            thousandsSeparator: maskOptions.thousandsSeparator,
            locale: 'pt-BR'
          }"
          :disabled="readonly"
          class="vuetify-money"
          :error-messages="errorMessages"
          @input="changed"
        />
      </div>
    </div>

    <v-slider
      v-if="!readonly"
      :value="amount"
      track-color="#485cc74d"
      :min="min"
      :max="max"
      thumb-label
      :thumb-size="100"
      :step="step"
      @change="changed"
    >
      <template #thumb-label="item">
        {{ formatValues(item.value) }}
      </template>
    </v-slider>
    <div v-if="!readonly" class="d-flex justify-space-between">
      <div class="text-left mt-n6 mb-6">
        Mín.
        <br />
        <strong>{{ formatValues(min) }}</strong>
      </div>
      <div class="text-right mt-n6 mb-6">
        Máx.
        <br />
        <strong>{{ formatValues(max) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import formatCurrency from "@/utils/formatCurrency";
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000
    },
    step: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    currency: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      amount: 0
    };
  },
  computed: {
    errorMessages() {
      const errors = [];
      if (!this.readonly) {
        if (this.amount > this.max) {
          errors.push(
            "Máximo: " + this.formatValues(this.max, this.maskOptions)
          );
        }
        if (this.amount < this.min) {
          errors.push(
            "Mínimo: " + this.formatValues(this.min, this.maskOptions)
          );
        }
      }
      return errors;
    },
    maskOptions() {
      return {
        symbol: this.currency ? "R$ " : "",
        fractionCount: this.currency ? 2 : 0,
        thousandsSeparator: this.currency ? "." : ""
      };
    }
  },
  watch: {
    value() {
      this.amount = this.value + 0;
    }
  },
  created() {
    this.amount = this.value + 0;

    if (!this.amount) {
      this.amount = (this.max + this.min) / 2;
      this.changed(this.amount);
    }
  },
  methods: {
    formatValues(value) {
      if (this.currency) {
        return formatCurrency(value);
      }
      return value;
    },
    changed(amount) {
      if (!this.readonly && this.amount <= this.max) {
        this.$emit("input", Number(amount));
      }
    }
  }
};
</script>

<style lang="scss">
.slider-input {
  font-size: 16px;
  color: $darkBlue;
  .vuetify-money {
    max-width: 338px;
    .v-input {
      .v-input__slot {
        padding: 7px 23px !important;
        fieldset {
          border-radius: 9px !important;
          border-color: $darkBlue;
        }
        input,
        .v-text-field__prefix {
          color: $darkBlue;
          font-weight: bold;
          font-size: 40px;
          text-align: right;
          max-height: 55px;
          @media (max-width: 1455px) {
            font-size: 30px;
          }
        }
        .v-text-field__prefix {
          color: $darkBlue;
        }
      }
    }
  }
  .v-slider__track-container {
    height: 6px;
  }
  .v-slider__thumb {
    width: 34px;
    height: 34px;
    // background: url('@/assets/slider-icon.png');
    background-position: center;
    background-repeat: no-repeat;
  }
  .v-slider__track-background,
  .v-slider__track-fill {
    border-radius: 5px;
  }
  .v-slider__thumb:before {
    left: -1px;
    top: -1px;
  }
  .v-slider__thumb-label {
    left: 10px;
  }
}
</style>
