
import Modal from "@/components/Modal.vue";
import SaveButton from "@/components/SaveButton.vue";
import MarginBaseService, {
  ConsignetPartnership,
  ConsignetRegistration
} from "@/services/margin-base-service";
import { format as formatCNPJ } from "@/utils/cnpj";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import Company from "../../types/company";
import formatCurrency from "@/utils/formatCurrency";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";

interface ConsignetData {
  consignetRegistrationId: number | null;
  consignetPartnershipId: number | null;
  consignetRegistrationMarginBaseValue: number | null;
}

@Component({
  components: { ValidationObserver, Modal, SaveButton },
  directives: { mask }
})
export default class ConsignetDataSelection extends Vue {
  @Prop({ type: String, default: "" }) cpf!: string;
  @Prop({ type: Array, default: [] }) consignetRelatedCompanies!: Company[];
  marginBaseService: MarginBaseService;

  emptyForm: ConsignetData = {
    consignetPartnershipId: null,
    consignetRegistrationId: null,
    consignetRegistrationMarginBaseValue: null
  };
  form: ConsignetData = JSON.parse(JSON.stringify(this.emptyForm));

  loading: boolean = false;
  loadingConsignetEmployeeRegistrations: boolean = false;

  consignetEmployeeRegistrations: ConsignetRegistration[] | null = null;

  formatCNPJ = formatCNPJ;

  constructor() {
    super();

    this.marginBaseService = MarginBaseService.getInstance();
  }

  async findValidConsignetEmployeeRegistrations() {
    this.loadingConsignetEmployeeRegistrations = true;

    const [error, response] =
      await this.marginBaseService.findValidConsignetEmployeeRegistrations({
        cpf: this.cpf.replace(/\D/g, ""),
        consignetPartnershipId: this.form.consignetPartnershipId!
      });

    this.loadingConsignetEmployeeRegistrations = false;

    if (error || !response) {
      const message =
        getErrorMessageFromApiError(error) ||
        "Erro ao buscar as matrículas do funcionário";

      return this.$notify({
        title: "Erro",
        text: message,
        type: "error"
      });
    }

    if (!response?.length) {
      return this.$notify({
        text: "Não foram encontradas matrículas aptas a contratação para o CPF informado.",
        type: "warning"
      });
    }

    this.consignetEmployeeRegistrations = response!;
  }

  async findConsignetCurrentEmployeeRegistrationMarginBase() {
    this.loading = true;
    const [error, response] =
      await this.marginBaseService.findCurrentConsignetEmployeeRegistrationMarginBase(
        {
          consignetRegistrationId: this.form.consignetRegistrationId!,
          consignetPartnershipId: this.form.consignetPartnershipId!,
          cpf: this.cpf.replace(/\D/g, "")
        }
      );

    this.loading = false;

    if (error || !response) {
      const message =
        getErrorMessageFromApiError(error) ||
        "Erro ao buscar as bases de margem do funcionário.";

      return this.$notify({
        title: "Erro",
        text: message,
        type: "error"
      });
    }

    if (response?.availableMarginValue <= 0) {
      return this.$notify({
        text: "O CPF não possui margem disponível para contratação.",
        type: "warning"
      });
    }

    this.form.consignetRegistrationMarginBaseValue =
      response.availableMarginValue;
  }

  async createConsignetMarginBase() {
    this.loading = true;
    const [error, response] =
      await this.marginBaseService.createConsignetMarginBase({
        consignetRegistrationId: this.form.consignetRegistrationId!,
        consignetPartnershipId: this.form.consignetPartnershipId!,
        cpf: this.cpf.replace(/\D/g, "")
      });

    this.loading = false;

    if (error || !response) {
      const consignetExceptionMessage =
        error.response.data?.code == "ConsignetProviderException"
          ? error.response.data?.message
          : null;

      const messsage =
        consignetExceptionMessage ||
        getErrorMessageFromApiError(error) ||
        "Erro ao selecionar a base de margem do funcionário.";

      return this.$notify({
        title: "Erro",
        text: messsage,
        type: "error"
      });
    }

    this.$emit("save");
    return this.$notify({
      type: "success",
      text: "Base de margem selecionada com sucesso!"
    });
  }

  formatCurrency = (value: number) => {
    return formatCurrency(value);
  };
}
