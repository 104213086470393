
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";
import LoanStatus, { LoanStatusEnum } from "@/types/loan-status";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import dayjs from "dayjs";
import DatePicker from "@/components/DatePicker.vue";
import { BankingEnum } from "../../types/BankingEnum";
import { FundsEnum } from "../../types/FundsEnum";

@Component({
  components: { ValidationObserver, DatePicker }
})
export default class LoanUpdateStatusModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  observation: string = "";
  status: string | null = null;
  paidDate: string | null = null;
  LoanStatusEnum = LoanStatusEnum;

  constructor() {
    super();
  }

  handleUpdateStatus(): void {
    this.isLoading = true;
    this.$emit("input", {
      id: this.item?.id,
      status: this.status,
      observation: this.observation || "",
      paidDate: this.paidDate || ""
    });
    this.status = null;
    this.observation = "";
    this.paidDate = "";
  }

  get possibleStatus(): Array<LoanStatus> {
    return this.item?.status?.possibleStatus || [];
  }

  get allowedPossibleStatus(): Array<LoanStatus> {
    return this.possibleStatus.filter(
      (loanStatus) =>
        !this.notAllowedPossibleStatus.find(
          (notAllowedLoanStatus) => notAllowedLoanStatus.id === loanStatus.id
        )
    );
  }

  get notAllowedPossibleStatus(): Array<LoanStatus> {
    let notAllowed: LoanStatus[] = [LoanStatusEnum.PAID_OFF];

    if (
      this.item.bankingId === BankingEnum.LECCA.id &&
      this.item.fundId === FundsEnum.SANTA_BARBARA.id
    ) {
      // Lecca proposals are automatically set to Payment In Process by the system
      notAllowed.push(LoanStatusEnum.PAYMENT_IN_PROCESS);
    } else {
      // Payment inclusion flow is for Lecca proposals only
      notAllowed.push(LoanStatusEnum.PAYMENT_INCLUSION);
    }

    return notAllowed;
  }

  get total(): string {
    return formatCurrency(this.item?.total || 0);
  }

  get creditAmount(): string {
    return formatCurrency(this.item?.creditAmount || 0);
  }

  get startedSimulationDate(): string {
    return this.formatDate(this.item?.startedSimulationDate);
  }

  get borrowerCpf(): string {
    return formatCPF(this.item?.borrowerCpf);
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }
}
