export default interface PixType {
  id: number;
  name: string;

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

const email: PixType = {
  id: 1,
  name: "E-MAIL"
};

const CPF: PixType = {
  id: 2,
  name: "CPF"
};

const phone: PixType = {
  id: 3,
  name: "Telefone"
};

const random: PixType = {
  id: 4,
  name: "Aleatória"
};

export const PixTypeEnum = {
  EMAIL: email,
  CPF: CPF,
  PHONE: phone,
  RANDOM: random
};

export const PixTypeNames = Object.values(PixTypeEnum).map((type) => type.name);

