
import Loan from "@/types/loan";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import LoanService from "@/services/loan-service";
import NonEndorsementMotive from "@/types/non-endorsement-motive";
import { NonEndorsementMotivesIdsEnum } from "@/types/non-endorsement-motive";
import LoanInfo from "./LoanInfo.vue";

@Component({ components: { LoanInfo } })
export default class LoanRefuseModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;

  service: LoanService;
  nonEndorsementMotives: NonEndorsementMotive[] = [];
  nonEndorsementMotivesIdsEnum = NonEndorsementMotivesIdsEnum;
  nonEndorsementMotiveId: number | null = null;
  endorsementObservation: string | null = "";
  nonEndorsementAvailableMargin: number | null = null;
  loading = false;

  constructor() {
    super();
    this.service = LoanService.getInstance();
  }

  @Watch("show")
  async showChanged(val: string): Promise<void> {
    if (val && this.nonEndorsementMotives?.length === 0) {
      this.loading = true;
      const [nonEndorsementMotivesError, nonEndorsementMotives] =
        await this.service.listAllNonEndorsementMotive();

      if (nonEndorsementMotivesError || !nonEndorsementMotives) {
        this.$notify({
          type: "error",
          text: "Não foi possível carregar a lista de motivos."
        });
      } else {
        nonEndorsementMotives.forEach((motive: NonEndorsementMotive) => {
          this.nonEndorsementMotives.push(motive);
        });
      }

      this.loading = false;
    }
  }

  async handleRefuse(): Promise<void> {
    this.$emit("input", {
      loan: this.item,
      nonEndorsementMotiveId: this.nonEndorsementMotiveId,
      endorsementObservation: this.endorsementObservation,
      nonEndorsementAvailableMargin: this.nonEndorsementAvailableMargin
    });

    this.nonEndorsementAvailableMargin = null;
    this.endorsementObservation = null;
    this.nonEndorsementAvailableMargin = null;
  }
}
