
import Loan from "@/types/loan";
import { Vue, Component, Prop } from "vue-property-decorator";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import formatCurrency from "@/utils/formatCurrency";

@Component
export default class LoanInfo extends Vue {
  @Prop() readonly item!: Loan;

  formatCPF = formatCPF;
  formatCNPJ = formatCNPJ;
  formatCurrency = formatCurrency;

  constructor() {
    super();
  }
}
