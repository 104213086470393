export default interface NonEndorsementMotive {
  id: number;
  name: string;
  readableName: string;
}


export enum NonEndorsementMotivesIdsEnum {
  EMPLOYEE_AWAY = 1,
  FIRED_EMPLOYEE = 2,
  EMPLOYEE_ON_VACATION = 3,
  NON_EXISTENT_EMPLOYEE = 4,
  MARGIN_GREATER_THAN_ALLOWED = 5,
  DOES_NOT_MEET_CONCESSION_RULES = 6,
  OTHERS_REASONS = 7
}
