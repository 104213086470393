
import Modal from "@/components/Modal.vue";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import formatDate from "@/utils/formatDate";
import LoanService from "../../services/loan-service";
import formatCurrency from "@/utils/formatCurrency";
import LoanInstallment from "@/types/loan-installment";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanInstallmentBalanceRecord from "@/types/loan-installment-balance-record";
import { PaymentOriginMap } from "@/types/payment-origin";

@Component({
  components: {
    ValidationObserver,
    Modal
  }
})
export default class LoanInstallmentModal extends Vue {
  @Prop() readonly item!: LoanInstallment;
  loadingBalance: boolean = false;
  installmentBalance: LoanInstallmentBalanceRecord[];
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatDate = formatDate;
  formatCurrency = formatCurrency;
  PaymentOriginMap = PaymentOriginMap;
  loanService: LoanService;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.installmentBalance = [];
  }

  async created(): Promise<void> {
    await this.fetchInstallmentBalance();
  }

  async fetchInstallmentBalance(): Promise<void> {
    this.loadingBalance = true;
    const [error, installmentBalance] =
      await this.loanService.listBalanceRecordsByInstallmentId(this.item.id);

    if (error || !installmentBalance) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    this.installmentBalance = installmentBalance;

    this.loadingBalance = false;
  }

  close(): void {
    this.$emit("closeInstallmentModal");
  }

  paymentOriginName(originId: number): string {
    return PaymentOriginMap[originId] || "";
  }
}
