/*
 * All existing loan types of payments
 */

const ted = {
  id: 1,
  name: "TED"
};

const pix = {
  id: 2,
  name: "PIX"
};

/*
 * Enum
 */

export const LoanPaymentTypeEnum = {
  TED: ted,
  PIX: pix
};

export const LoanPaymentTypeIds = Object.values(LoanPaymentTypeEnum).map(
  (type) => type.id
);

