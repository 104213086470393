
import Loan from "@/types/loan";
import { Vue, Component, Prop } from "vue-property-decorator";
import LoanInfo from "./LoanInfo.vue";

@Component({ components: { LoanInfo } })
export default class LoanApprovalModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;

  constructor() {
    super();
  }

  handleApprove() {
    this.$emit("input", this.item);
  }
}
