/* eslint-disable no-use-before-define */
import GlobalParams from "@/types/globalParams";
import HttpClient, { Response } from "./http-client";

export default class FinancialCompanyService extends HttpClient {
  private static classInstance?: FinancialCompanyService;

  private constructor() {
    super(process.env.VUE_APP_FINANCIAL_COMPANY_URL!);
  }

  public static getInstance(): FinancialCompanyService {
    if (!this.classInstance) {
      this.classInstance = new FinancialCompanyService();
    }

    return this.classInstance;
  }

  public async simulateProposal(
    data: SimulateProposalData
  ): Promise<Response<SimulateProposalResponse>> {
    return this.axiosCall<SimulateProposalResponse>({
      method: "POST",
      url: "/proposal/simulate",
      data
    });
  }

  public async internalSimulateProposal(
    data: InternalSimulateProposalData
  ): Promise<Response<SimulateProposalResponse>> {
    return this.axiosCall<SimulateProposalResponse>({
      method: "POST",
      url: "/proposal/internal-simulate",
      data
    });
  }

  public async includeProposal(
    data: IncludeProposalData
  ): Promise<Response<IncludeProposalResponse>> {
    return this.axiosCall<IncludeProposalResponse>({
      method: "POST",
      url: "/proposal/include",
      data
    });
  }

  public async saveLegalPerson(
    data: SaveLegalPersonData
  ): Promise<Response<SaveLegalPersonResponse>> {
    return this.axiosCall<SaveLegalPersonResponse>({
      method: "POST",
      url: "/proposal/save-legal-person",
      data
    });
  }

  public async saveLegalPersonAddress(
    data: SaveLegalPersonAddressData
  ): Promise<Response<SaveLegalPersonAddressResponse>> {
    return this.axiosCall<SaveLegalPersonAddressResponse>({
      method: "POST",
      url: "/proposal/save-legal-person-address",
      data
    });
  }

  public async getGlobalParamsBySegment(
    segmenTypeId: number
  ): Promise<Response<GlobalParams[]>> {
    return this.axiosCall<GlobalParams[]>({
      method: "GET",
      url: `/global-params/${segmenTypeId}`
    });
  }

  public async updateGlobalParamsBySegment(
    data: UpdateGlobalParamsData
  ): Promise<Response<GlobalParams[]>> {
    return this.axiosCall<GlobalParams[]>({
      method: "POST",
      url: `/global-params/update-by-segment`,
      data
    });
  }
}

interface SimulateProposalData {
  bankingId: number;
  loanType: string;
  amount: number;
  installments: number;
  negotiatedFee: number;
  withInsurance: boolean;
  marginBaseRegisterId: number;
  /** Required when banking is MoneyPlus */
  segmentId?: number;
}

interface InternalSimulateProposalData {
  bankingId: number;
  loanType: string;
  amount: number;
  installments: number;
  negotiatedFee: number;
  withInsurance: boolean;
  marginBaseRegisterId: number;
}

export interface SimulateProposalResponse {
  insuranceValue: number;
  tacValue: number;
  iofPercentual: number;
  iofValue: number;
  billetValue: number;
  requestedValue: number;
  creditAmount: number;
  debitAmount: number;
  installmentsTotal: number;
  installmentsValue: number;
  installments: ProposalInstallment[];
  monthlyFeePercentual: number;
  annualFeePercentual: number;
  monthlyCETPercentual: number;
  annualCETPercentual: number;
}

interface IncludeProposalData {
  operationCode: string;
  document: string;
  amount: string;
  installments: number;
  bank: {
    bankCode: string;
    accountType: string;
    agency: string;
    agencyDigit: string;
    account: string;
    accountDigit: string;
  };
  withInsurance: boolean;
  loanType: string;
}

export interface IncludeProposalResponse {
  proposalCode: string;
}

interface SaveLegalPersonData {
  document: string;
  personal: {
    name: string;
    phone: string;
    birthday: string;
    rg: string;
    rgState: string;
    rgIssuer: string;
    birthCity: string;
    birthState: string;
    maritalStatus: string;
    admissionDate: string;
  };
  /** Required when banking is MoneyPlus */
  segmentId?: number;
}

interface SaveLegalPersonResponse {
  legalPersonCode: string;
}

interface SaveLegalPersonAddressData {
  document: string;
  address: {
    postalCode: string;
    street: string;
    number: string;
    district: string;
    city: string;
    stateAbbreviation: string;
    complement: string;
  };
  /** Required when banking is MoneyPlus */
  segmentId?: number;
}

interface UpdateGlobalParamsData {
  segmentTypeId: number;
  globalParamsList: GlobalParams[];
}

interface SaveLegalPersonAddressResponse {
  legalPersonAddressCode: string;
}

interface ProposalInstallment {
  number: number;
  value: number;
  fee: number;
  debitBalance: number;
  dueDate: string; // utc
}
