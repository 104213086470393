
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import LoanService from "@/services/loan-service";
import { mask } from "vue-the-mask";
import SaveButton from "@/components/SaveButton.vue";
import { SaveLoanResponse } from "@/services/loan-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import accountTypes from "@/data/tipos-de-conta";
import banks from "@/data/bancos";
import UploadFile from "@/components/UploadFile.vue";
import AlertBox from "@/components/AlertBox.vue";
import CompanyService from "@/services/company-service";
import { BankingEnum } from "@/types/BankingEnum";
import PixType, { PixTypeEnum } from "@/types/pix-types";
import { LoanPaymentTypeEnum } from "@/types/LoanPaymentTypeEnum";

interface DocumentType {
  value: string;
  label: string;
  descriptionHtml?: string;
  optional?: boolean;
  maxFiles?: number;
}

interface State {
  form: any;
}

@Component({
  components: { ValidationObserver, Modal, SaveButton, UploadFile, AlertBox },
  directives: { mask }
})
export default class LoanUploadDocuments extends Vue {
  @Prop() readonly loan!: SaveLoanResponse;
  /** Used to preserve state (i.e., all relevant data) during re-renders caused by reactive behavior */
  @Prop() readonly editState!: State;
  /** Used on component creation to avoid treating programmatic changes to form as if they were actual user input */
  isProgrammaticFormInput: boolean = false;
  companyService: CompanyService;
  loading: boolean = false;
  loanService: LoanService;
  paymentMethodOptions = [{ text: "TED" }, { text: "PIX" }];
  selectedPaymentMethod =
    this.loan.loan.loanPaymentTypeId === LoanPaymentTypeEnum.PIX.id
      ? "PIX"
      : "TED";
  bucket: string;
  filesUrl: string;
  accountTypes = accountTypes;
  banks = banks;
  pixTypes: PixType[] = [];
  PixTypeEnum = PixTypeEnum;
  LoanPaymentTypeEnum = LoanPaymentTypeEnum;

  selectedDocumentType: string = "cnh";
  BankingEnum = BankingEnum;
  form = {
    loan: {
      bank: this.loan.loan.bank,
      company: this.loan.marginBaseRegister.company,
      branch: this.loan.loan.branch,
      checkingAccount: this.loan.loan.checkingAccount,
      accountType: this.loan.loan.accountType || 1,
      pixTypeId: this.loan.loan.pixTypeId,
      pixKey: this.loan.loan.pixKey,
      loanPaymentTypeId: this.loan.loan.loanPaymentTypeId ?? 1
    }
  };

  documents: {
    url: string;
    type: string;
  }[] = [
    ...(this.loan.loan.documents?.map((document) => ({
      url: document.url,
      type: document.type.name
    })) || [])
  ];

  constructor() {
    super();

    this.loanService = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.bucket = process.env.VUE_APP_BORROWER_DOCUMENTS_BUCKET || "";
    this.filesUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  @Watch("form", { deep: true })
  async formChanged(): Promise<void> {
    this.onUnsavedChanges();
  }

  @Watch("selectedPaymentMethod", { deep: true })
  async changeOnTypeOfPayment(): Promise<void> {
    if (this.selectedPaymentMethod === "TED") {
      this.form.loan.loanPaymentTypeId = LoanPaymentTypeEnum.TED.id;
    } else {
      this.form.loan.loanPaymentTypeId = LoanPaymentTypeEnum.PIX.id;
    }
    this.form.loan.accountType = this.loan.loan.accountType || 1;
    this.form.loan.checkingAccount = this.loan.loan.checkingAccount;
    this.form.loan.pixTypeId = this.loan.loan.pixTypeId;
    this.form.loan.pixKey = this.loan.loan.pixKey;
  }

  @Watch("form.loan.pixTypeId", { deep: true })
  async changePixKey(): Promise<void> {
    this.form.loan.pixKey = "";
  }

  async created(): Promise<void> {
    await this.fetchPixTypes();
    if (!isEmpty(this.editState)) {
      this.form = cloneDeep(this.editState.form);
    } else {
      this.isProgrammaticFormInput = true;
      // Programmatic changes on component creation go here
      this.$nextTick(() => {
        this.isProgrammaticFormInput = false;
      });
    }
  }

  public async documentUploaded(
    uploadedFiles: Array<{
      fileKey: string;
      bucket: string;
    }>,
    documentType: string
  ): Promise<void> {
    const urls = uploadedFiles.map(
      (uploadedFile) => uploadedFile.bucket + "/" + uploadedFile.fileKey
    );

    const [error, response] = await this.loanService.saveLoanDocuments({
      cpf: this.loan.borrower.cpf,
      data: {
        type: documentType,
        urls
      }
    });

    if (error) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      return;
    }

    if (response) {
      this.$notify({
        title: "Sucesso",
        text: `Arquivo(s) salvo(s) com sucesso.`,
        type: "success"
      });

      // Remove existing documents of this type
      this.documents = this.documents.filter(
        (document) => document.type !== documentType
      );

      response.forEach((document) => {
        this.documents.push({
          url: document.url,
          type: documentType
        });
      });
      // this.$emit("input", response);
    }
  }


  async fetchPixTypes() {
    this.loading = true;

    const [error, pixTypesData] = await this.loanService.getPixTypes();
    if (!error) {
      this.pixTypes = pixTypesData;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;
  }

  getDocumentUrls(documentType: string): string[] {
    return this.documents
      .filter((document) => document.type === documentType)
      .map((document) => this.filesUrl + "/download/" + document.url);
  }

  isImg(url: string | undefined): boolean {
    if (url) {
      return (
        url.endsWith(".png") || url.endsWith(".jpg") || url.endsWith(".jpeg")
      );
    }
    return false;
  }

  async save(): Promise<void> {
    this.loading = true;

    const [loanError, updatedLoan] = await this.loanService.saveLoanCalculate({
      cpf: this.loan.borrower.cpf,
      data: {
        loan: {
          bank: this.form.loan.bank,
          bankNationalCode: banks.find(
            (bank) => bank.value === this.form.loan.bank
          )!.bankNationalCode,
          branch: this.form.loan.branch,
          checkingAccount: this.form.loan.checkingAccount,
          accountType: Number(this.form.loan.accountType),
          pixKey: this.form.loan.pixKey,
          pixTypeId: this.form.loan.pixTypeId,
          loanPaymentTypeId: this.form.loan.loanPaymentTypeId
        }
      }
    });

    if (loanError) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(loanError),
        type: "error"
      });
    }

    if (updatedLoan) {
      this.$notify({
        title: "Sucesso",
        text: "Dados de Pagamento e Documentação salvos com sucesso.",
        type: "success"
      });

      this.$emit("updatedLoan", updatedLoan);
      this.goToNextStep();
    }

    this.loading = false;
  }

  onUnsavedChanges(): void {
    if (!this.isProgrammaticFormInput) {
      this.$emit("unsavedChanges");
    }
    const data: State = {
      form: this.form
    };
    this.$emit("updateEditState", data);
  }

  goToPreviousStep(): void {
    this.$emit("previous");
  }

  goToNextStep(): void {
    this.$emit("next");
  }

  get allowPaymentMethodChange(): boolean {
    return false;
  }

  get areRequiredDocumentsUploaded(): boolean {
    return this.documentTypes
      .filter((documentType) => !documentType.optional)
      .every((documentType) => this.getDocumentUrls(documentType.value).length);
  }

  get documentTypes(): Array<DocumentType> {
    const rosto: DocumentType = { value: "ROSTO", label: "Selfie tomador" };
    const cnh: DocumentType = { value: "CNH", label: "CNH" };
    const rgFrente: DocumentType = { value: "RG_FRENTE", label: "RG Frente" };
    const rgVerso: DocumentType = { value: "RG_VERSO", label: "RG Verso" };
    const comprovanteRenda: DocumentType = {
      value: "COMPROVANTE_RENDA",
      label: "Comprovante de renda"
    };
    const comprovanteResid: DocumentType = {
      value: "COMPROVANTE_RESID",
      label: "Comprovante de residência"
    };
    const carteiraTrabalho: DocumentType = {
      value: "CARTEIRA_TRABALHO",
      label: "Carteira de Trabalho",
      descriptionHtml: `<b>Fisica:</b> Página da foto, verso, registro, e observação da alteração de salário.
                    <br />
                    <b>Digital:</b> Registro atual com os dados pessoais.
                    <br />
                    Envie até 5 arquivos, selecionando todos de uma vez.`,
      optional: true,
      maxFiles: 5
    };

    if (
      !this.loan.marginBaseRegister.company.group?.requireBorrowerSendDocuments
    ) {
      if (this.selectedDocumentType === "cnh") {
        return [cnh, rosto];
      } else if (this.selectedDocumentType === "rg") {
        return [rgFrente, rgVerso, rosto];
      }
    } else {
      if (this.selectedDocumentType === "cnh") {
        return [
          cnh,
          comprovanteRenda,
          comprovanteResid,
          rosto,
          carteiraTrabalho
        ];
      } else if (this.selectedDocumentType === "rg") {
        return [
          rgFrente,
          rgVerso,
          comprovanteRenda,
          comprovanteResid,
          rosto,
          carteiraTrabalho
        ];
      }
    }

    // should not be reached
    return [
      rgFrente,
      rgVerso,
      comprovanteRenda,
      comprovanteResid,
      rosto,
      carteiraTrabalho
    ];
  }
}
