
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import { format as formatCNPJ } from "@/utils/cnpj";
import dayjs from "dayjs";
import { LoanTypeEnum } from "@/types/loan-type";
import DebtPurchaseManage from "@/components/loans/DebtPurchaseManage.vue";
import LoanDebt from "@/types/loan-debt";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanService from "../../services/loan-service";
import PaymentService from "../../services/payment-service";
import AlertBox from "@/components/AlertBox.vue";
import { LoanStatusEnum } from "@/types/loan-status";
import Refinancing from "@/types/refinancing";

@Component({
  components: { ValidationObserver, DebtPurchaseManage, AlertBox }
})
export default class UpdateRefusedBankSlipModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  formatCNPJ = formatCNPJ;
  LoanTypeEnum = LoanTypeEnum;
  debts = [] as LoanDebt[];
  previousDebts = [] as LoanDebt[];
  debtsTotalValue = 0;
  requestObservation: string = "";
  formatCurrency = formatCurrency;
  loanService: LoanService;
  paymentService: PaymentService;
  maxAmount = 10;
  possibleStatus = [LoanStatusEnum.RESENT_PAYMENT_IN_PROCESS];
  status;
  refinancing: Refinancing;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();
    this.status = LoanStatusEnum.RESENT_PAYMENT_IN_PROCESS;
  }

  async created() {
    this.isLoading = true;
    if (this.isDebtPurchase) {
      await this.fetchDebts();
    }

    if (this.isRefinancingOfInProgressLoans) {
      await this.fetchRefinancing();
    }
    this.isLoading = false;
  }

  get total(): string {
    return formatCurrency(this.item?.total || 0);
  }

  get creditAmount(): string {
    return formatCurrency(this.item?.creditAmount || 0);
  }

  get startedSimulationDate(): string {
    return this.formatDate(this.item?.startedSimulationDate);
  }

  get borrowerCpf(): string {
    return formatCPF(this.item?.borrowerCpf);
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }

  get isDebtPurchase(): boolean {
    return this.item.typeId === this.LoanTypeEnum.DEBT_PURCHASE.id;
  }

  get isRefinancingOfInProgressLoans(): boolean {
    return (
      this.item.typeId === this.LoanTypeEnum.REFINANCING_OF_IN_PROGRESS_LOANS.id
    );
  }

  get sumOfValuesRespectAmount(): boolean {
    return this.debtsTotalValue == this.maxAmount;
  }

  async fetchDebts(): Promise<void> {
    this.isLoading = true;

    const [error, debts] = await this.loanService.findDebtsByLoanId(
      this.item.id
    );

    if (error || !debts) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
    this.debts = debts;
    this.previousDebts = debts;
    this.item.debts = debts; // Adicione o campo debts ao item Loan

    this.debtsTotalValue = this.debts.reduce(
      (sum, debt) => sum + (debt.value || 0),
      0
    );
    this.maxAmount = this.debtsTotalValue;
    this.isLoading = false;
  }

  async fetchRefinancing(): Promise<void> {
    this.isLoading = true;

    const [error, refinancing] = await this.loanService.findRefinancingByLoanId(
      this.item.id
    );

    if (error || !refinancing) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    this.refinancing = refinancing;

    const [bankSlipError, paymentBankslip] =
      await this.paymentService.findBankSlipByPaymentId(refinancing.paymentId);

    if (bankSlipError || !paymentBankslip) {
      this.isLoading = false;
      return this.$notify({
        type: "error",
        text: "Não foi encontrado boleto para pagamento"
      });
    }

    this.debtsTotalValue = Number(paymentBankslip.amount.currencyObj);
    this.maxAmount = Number(paymentBankslip.amount.currencyObj);
    this.isLoading = false;
  }

  async handleUpdateLoanDebts(): Promise<void> {
    if (this.isDebtPurchase) {
      const [debtsError, debts] =
        await this.loanService.resendRefusedDebtPurchase({
          loanId: this.item.id,
          debts: this.debts,
          requestObservation: this.requestObservation
        });
      this.isLoading = false;
      if (debtsError || !debts) {
        return this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(debtsError)
        });
      } else {
        this.$notify({
          type: "success",
          text: "Débitos reenviados para pagamento."
        });
        this.$emit("close");
      }
    }

    if (this.isRefinancingOfInProgressLoans) {
      const [refinancingError, refinancing] =
        await this.loanService.resendRefusedRefinancing({
          loanId: this.item.id,
          paymentId: this.refinancing.paymentId,
          requestObservation: this.requestObservation,
          refinancingId: this.refinancing.id
        });
      this.isLoading = false;
      if (refinancingError || !refinancing) {
        return this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(refinancingError)
        });
      } else {
        this.$notify({
          type: "success",
          text: "Refinanciamento reenviado para pagamento."
        });
        this.$emit("close");
      }
    }
  }

  updateDebtsTotalValue(sum: number): void {
    this.debtsTotalValue = sum;
  }
}
