import LoanStatus from "@/types/loan-status";
import LoanStatusHistory from "@/types/loan-status-history";
import Document from "@/types/document";
import User from "./user";
import LoanInstallment from "./loan-installment";
import LoanType from "./loan-type";
import NonEndorsementMotive from "./non-endorsement-motive";
import LoanDebt from "./loan-debt";
import LoanInstallmentRefinancing from "./loan-installment-refinancing";
import Refinancing from "./refinancing";

export default interface Loan {
  id: number;
  index?: number | null;
  type: LoanType;
  typeId: number;
  borrowerId: number;
  borrowerName: string;
  borrowerCpf: string;
  partnerName: string;
  partnerId: number;
  companyId: number;
  companyName: string;
  companyCnpj: string;
  clicksignSignatureKey?: string | null;
  clicksignDocumentKey?: string | null;
  clicksignWebhookEvent?: string | null;
  clicksignWebhookEventDate?: Date | null;
  statusId: number;
  statusHistory: LoanStatusHistory[];
  status: LoanStatus;
  financialInstitutionId: number;
  total: number;
  approvalDate?: Date | null;
  requestDate?: Date | null;
  startedSimulationDate?: Date | null;
  endorserUserId?: number | null;
  endorsementDate?: Date | null;
  endorsementObservation?: string | null;
  documents: Document[];
  installments?: LoanInstallment[] | null;
  debts?: LoanDebt[] | null;
  refinancing: Refinancing | null;
  nonEndorsementMotive?: NonEndorsementMotive;
  nonEndorsementAvailableMargin?: number | null;
  bankingId?: number | null;
  bankingName?: string | null;
  administratorId?: number | null;
  administratorName?: string | null;
  fundId?: number | null;
  fundName?: string | null;
  checkingAccount?: string | null;
  bank?: string | null;
  bankNationalCode?: string | null;
  branch?: string | null;
  accountType?: string | null;
  cancelationMotive?: string | null;
  requestedAmount?: number | null;
  iofValue?: number | null;
  tacValue?: number | null;
  creditAmount?: number | null;
  insuranceValue?: number | null;
  paidDate?: string | null;
  updated_at: string;
  monthlyFee: number;
  annualFee: number;
  proposalNumber: string;
  paymentInProcessDate?: Date | null;
  loanPaymentTypeId: number;
  pixKey?: string;
  // Front-only
  amount: number;
  maxAmount: number;
  numInstallments: number;
  maxInstallments: number;
  installmentValue: number;
  extraDays: number;
  interestRate: number;
  user?: User;
}

export const loanTypesIds: { [key: string]: number } = {
  EMPRESTIMO_CONSIGNADO: 1,
  ANTECIPACAO_DE_SALARIO: 2,
  COMPRA_DE_DIVIDA: 3,
  REFINANCIAMENTO_CONTRATOS_ANDAMENTO: 4
};

export const loanTypesNames: { [id: number]: string } = {
  1: "EMPRESTIMO_CONSIGNADO",
  2: "ANTECIPACAO_DE_SALARIO",
  3: "COMPRA_DE_DIVIDA",
  4: "REFINANCIAMENTO_CONTRATOS_ANDAMENTO"
};
