
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import { BorrowerSigningMethod } from "../../types/borrower";
import dayjs from "dayjs";

@Component({
  components: { ValidationObserver }
})
export default class LoanUpdateSigningMethod extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  signingMethod: string | null = null;
  signingMethods = [
    { text: "SMS", value: BorrowerSigningMethod.SMS },
    { text: "WhatsApp", value: BorrowerSigningMethod.WHATSAPP }
  ];

  constructor() {
    super();
  }

  handleUpdateSigningMethod(): void {
    this.isLoading = true;
    this.$emit("input", {
      id: this.item.id,
      signingMethod: this.signingMethod
    });
    this.signingMethod = null;
  }

  get total(): string {
    return formatCurrency(this.item?.total || 0);
  }

  get creditAmount(): string {
    return formatCurrency(this.item?.creditAmount || 0);
  }

  get startedSimulationDate(): string {
    return this.formatDate(this.item?.startedSimulationDate);
  }

  get borrowerCpf(): string {
    return formatCPF(this.item?.borrowerCpf);
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }
}
