

import { Component, Prop, Vue } from "vue-property-decorator"
import IBankAccount from '@/types/IBankAccount'
import banks from '@/data/bancos'
import accountTypes from '@/data/tipos-de-conta'
import Loan from "@/types/loan";
import { ValidationObserver } from "vee-validate";
import { mask } from "vue-the-mask";
import LoanService from "@/services/loan-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
    components: { ValidationObserver },
    directives: { mask }
})
export default class BankAcountModal extends Vue {
    @Prop() item!: Loan;

    loanService: LoanService;

    dialog = false
    loading = false
    banks = banks
    accountTypes = accountTypes

    form: IBankAccount = {
        bank: '',
        branch: '',
        checkingAccount: '',
        accountType: '2'
    };

    constructor() {
        super();
        this.loanService = LoanService.getInstance();
    }

    async save() {
        let error;

        if (this.item) {
            [error] = await this.loanService.updateLoanBankingData(this.item.id, this.form);
        }

        if (!error) {
            this.$notify({ type: "success", text: "Dados salvo com sucesso!" });
        } else {
            this.$notify({
                type: "error",
                text: getErrorMessageFromApiError(error)
            });
        }
    }

    created() {
        this.form.bank = this.item.bank
        this.form.branch = this.item.branch
        this.form.checkingAccount = this.item.checkingAccount
        this.form.accountType = this.item.accountType
    }

}
