import dayjs from "dayjs";

export const checkIfLoanFlowCacheIsRecent = (
  cacheDate: Date | null
): boolean => {
  const timeToExpireLoanFlowCacheInHours = 3;

  return (
    !!cacheDate &&
    dayjs(cacheDate).isAfter(
      dayjs().subtract(timeToExpireLoanFlowCacheInHours, "hours")
    ) &&
    dayjs(cacheDate).day() === dayjs().day()
  );
};
